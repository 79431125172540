<!-- Finder expansible de Documentos -->

<template>
  <div class="common_docs_XM" v-if="schema">
    <v-sheet :elevation="4">

      <!-- Botones Mto -->
      <div class="pt-2 d-flex justify-left">
        <!-- Mto -->
        <btramto
          :btra_permisos="schema.btra"
          :btra_particular="Entorno.btra"
          btra_modulo="xm"
          :estado="estado"
          @onEvent="event_btra_Mto">
        </btramto>
      </div>

      <!-- Controles del Mto -->
      <div class="contenedor">
        <div style="display:flex">
          <v-text-field
            style="flex: 0 0 60%"
            v-bind="$input"
            v-model="schema.ctrls.name.value"
            :label="schema.ctrls.name.label"
            :disabled="!is_edit">
          </v-text-field>

          <v-select
            style="flex: 0 0 40%"
            v-bind="$select"
            v-model="schema.ctrls.tpd.value"
            :label="schema.ctrls.tpd.label"
            :items="itemsTipoDoc"
            :disabled="!is_edit"
            item-value="id"
            item-text="name">
          </v-select>
        </div>

        <v-textarea
          v-bind="$textarea"
          v-model="schema.ctrls.des.value"
          :label="schema.ctrls.des.label"
          rows="2"
          no-resize
          :disabled="!is_edit">
        </v-textarea>

        <v-file-input
          v-model="files"
          accept="application/pdf"
          chips
          clearable
          dense
          rounded
          small-chips
          :disabled="estado != 'nuevo'">
        </v-file-input>

        <div style="display:flex">
          <compfecha :schema="schema.ctrls.fhhr" :edicion="false"></compfecha>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.usu.value"
            :label="schema.ctrls.usu.label"
            :disabled="true">
          </v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.doc.value"
            :label="schema.ctrls.doc.label"
            :disabled="true">
          </v-text-field>


        </div>
      </div>
    </v-sheet>
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js";
  import { mixinDocs_XM } from "@/mixins/mixinDocs_XM.js";
  import plugs from "@/common/general_plugs";
  const btramto = () => plugs.groute("btramto.vue", "comp");
  const compfecha = () => plugs.groute("compfecha.vue", "comp");

  export default {
    mixins: [mixinMto, mixinDocs_XM],
    components: { btramto, compfecha },
    props: {
      padre: { type:String, default: '' },
      id: { type:[String, Number], default: '' },
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: ()=> {}},
      disparo: { type:Boolean, default:false },
      tip: { type: String, default: "0" },
      componenteTipo: { type:String, default:'M' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },


    data() {
      return {
        schema: null,
        api:'common_Docs_M',
        stName:'stMcommon_docs',
        disparoFinder:false,
        campo_relacionado: "tip_id",
        files:null,
        itemsTipoDoc:[]
      };
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;

        // configuro Entorno
        this.Entorno.header.header= false;
        this.Entorno.btra= "XM";

        // item adicionales
        // tipo de documentos
        this.itemsTipoDoc= JSON.parse(JSON.stringify(this.$store.state.datos_iniciales.tipo_documento));
        this.itemsTipoDoc= this.itemsTipoDoc.filter(elem => elem.modulos.charAt(this.tip== 1));
      },

    },

};
</script>
