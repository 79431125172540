
export var mixinDocs_XM = {

  methods: {    

    // borrar registro de documento según id recibido (override MixinMto)
    async borrar_registro() {
      var apiResult= await this.$store.dispatch(this.stName + '/borrar', { 
        tip: this.tip,
        id: this.accionRow.id 
      });
  
      // muestro mensaje de error si se no se ha eliminado correctamente
      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, "error");
        return;
      }

      // acciones después de eliminar un registro
      this.borrar_fin(apiResult);

      //
      this.$root.$alert.open("Registro borrado satisfactoriamente", "success", 3000);
    },


    // acciones después de eliminar un registro (override MixinMto)
    borrar_fin(apiResult) {        
      // actualizo array de filtros           
      this.$store.commit(this.padre + '/data2State', { prop:'recordsDetalle', key:1, value: apiResult.r[1] });
      
      // emito eventos para filtrar y ordenar los registros del finder
      this.$emit('onEvent', { accion:'filtrar_records' });      
    },

        
    // guardar datos (override MixinMto)
    async guardar() {           
      // compruebo si hemos seleccionado un documento
      if (!this.files && this.estado == "nuevo") {
        this.$root.$alert.open("Debes seleccionar un documento", "error");
        return;
      }
      
      // guardo campo_relacionado del XM
      if (this.campo_relacionado) this.schema.ctrls[this.campo_relacionado].value= this.id;
      
      // guardo campo tip (tipo de módulo. Expedientes, Funerarias_sucursales, etc...)
      this.schema.ctrls.tip.value= this.tip;

      // obtengo par-valor controles
      var fn_args = { ctrls: this.ctrl2linea(this.schema.ctrls) };
      
      // monto argumentos para hacer la llamada a la API
      var args = { tipo: this.$store.state[this.stName].tipo, api: this.api, accion: "guardar", fn_args: JSON.stringify(fn_args) };
   console.log('args guardar: ', JSON.parse(JSON.stringify(args)));
  
      // paso los argumentos a FORMDATA
      var formData = new FormData();
      for (const key in args) {
        formData.append(key, args[key]);
      }

      formData.append("documentos", this.files);
      formData.append("token", sessionStorage.getItem("login") || null);

      // ejecuto llamada API      
      this.guardar_Api({ args: formData });
    },


    // acciones después de guardar (override MixinMto)
    guardar_fin(apiResult) {        
      // inicializo variables     
      if (this.files) this.files = null;
      console.log('apiResult guardar: ', JSON.parse(JSON.stringify(apiResult)));
      // actualizo array de filtros       
      this.$store.commit(this.padre + '/data2State', { prop:'recordsDetalle', key:1, value: apiResult.r[1] });
      
      // emito eventos para filtrar y ordenar los registros del finder
      this.$emit('onEvent', { accion:'filtrar_records' }); 
      this.$emit('onEvent', { accion:'ordenar_records' });           
    },


    // acciones después de cancelar la edición (override MixinMto)
    cancelar_fin() {
      if (this.files) this.files = null;     
    },


  }
};
